var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("page-toolbar", {
            attrs: {
              "previous-page-label": _vm.$t(
                "console.platforms.mturk.workers.toolbar.prevPageLabel"
              ),
              title: _vm.$t("console.platforms.mturk.workers.toolbar.title"),
            },
            scopedSlots: _vm._u([
              {
                key: "contents",
                fn: function () {
                  return [
                    _c("associate-qualification-dialog-button", {
                      attrs: {
                        client: _vm.client,
                        credentials: _vm.credentials,
                        "worker-ids": _vm.workerIds,
                        "checked-worker-ids": _vm.checkedWorkerIds,
                      },
                      on: { complete: _vm.onComplete },
                    }),
                    _c("send-email-dialog-button", {
                      attrs: {
                        client: _vm.client,
                        "worker-ids": _vm.workerIds,
                        "checked-worker-ids": _vm.checkedWorkerIds,
                      },
                      on: { complete: _vm.onComplete },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "11" } },
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              dense: "",
              "show-select": "",
              "sort-desc": "",
              headers: _vm.headers,
              items: _vm.workers,
              "item-key": "id",
              loading: _vm.loading,
              "sort-by": "_created_at",
              search: _vm.searchQuery,
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-card-title",
                      [
                        _c("v-spacer"),
                        _c("v-text-field", {
                          attrs: {
                            "single-line": "",
                            "hide-details": "",
                            "append-icon": "mdi-magnify",
                            label: _vm.$t(
                              "console.platforms.mturk.workers.table.searchLabel"
                            ),
                          },
                          model: {
                            value: _vm.searchQuery,
                            callback: function ($$v) {
                              _vm.searchQuery = $$v
                            },
                            expression: "searchQuery",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item._created_at",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.standardDateTimeFormat(item._created_at)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.checkedWorkers,
              callback: function ($$v) {
                _vm.checkedWorkers = $$v
              },
              expression: "checkedWorkers",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }